module.exports = {
  mode: "jit",
  // These paths are just examples, customize them to match your project structure
  content: ["./public/**/*.html", "./src/**/*.{js,jsx,ts,tsx,vue}"],
  darkMode: "class",
  theme: {
    extend: {
      colors: {
        primary: "#F4B000",
        secondary: "#FAFAFA",
        tertiary: "#1C1C1C",
        "primary-highlighted": "#FFBF1A",
        "secondary-highlighted": "#FFFFFF",
        "tertiary-highlighted": "#333333",
        "primary-darkened": "#D69B00",
        "secondary-darkened": "#E5E5E5",
        "tertiary-darkened": "#0D0D0D",
      },
      fontFamily: {
        heading: ["Anton"],
        body: ["Noto Sans"],
      },
    },
  },
  plugins: [require("@tailwindcss/forms"), require("@tailwindcss/typography")],
}
