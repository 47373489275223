import * as React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Button = ({ type, varient, icon, className, href, onClick, children }) => {
  const _className =
    "inline-block px-4 py-2 rounded-lg shadow-md font-bold cursor-pointer text-xl hover:scale-110 duration-200 " +
    (varient === "primary"
      ? "bg-primary hover:bg-primary-highlighted active:bg-primary-darkened "
      : "bg-secondary hover:bg-secondary-highlighted active:bg-secondary-darkened border border-gray-200 ") +
    (className ? className : "")

  const _icon = icon ? (
    <span className="pr-2">
      <FontAwesomeIcon icon={icon} />
    </span>
  ) : null

  return (
    <>
      {href ? (
        <a href={href} className={_className} onClick={onClick}>
          {_icon}
          {children}
        </a>
      ) : (
        <button type={type} className={_className} onClick={onClick}>
          {_icon}
          {children}
        </button>
      )}
    </>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  varient: PropTypes.oneOf(["primary", "secondary"]),
  href: PropTypes.string,
}

Button.defaultProps = {
  type: "button",
  varient: "primary",
}

export default Button
