/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import Button from "./button"
import Footer from "./footer"
import Header from "./header"

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isNavOpen, setNavOpen] = React.useState(false)

  return (
    <div
      className={
        "h-screen scroll-smooth" + (isNavOpen ? "overflow-hidden" : "")
      }
    >
      <div
        className={
          "flex flex-col min-h-screen " +
          (isNavOpen ? "overflow-hidden h-full" : "")
        }
      >
        <Header
          isNavOpen={isNavOpen}
          setNavOpen={setNavOpen}
          siteTitle={data.site.siteMetadata?.title || `Title`}
        />
        {!isNavOpen && (
          <>
            <main className="flex-auto">{children}</main>
            <Footer />
          </>
        )}
        {isNavOpen && (
          <div className="grow flex flex-col">
            <div className="basis-3/4 overflow-scroll">
              <nav className="bg-secondary divide-y-2 flex flex-col justify-center items-center">
                <Link
                  to="/about"
                  className="block text-center text-4xl px-16 py-6 font-bold hover:text-primary-highlighted duration-200"
                >
                  About
                </Link>

                <Link
                  to="/services"
                  className="block text-center text-4xl px-16 py-6 font-bold hover:text-primary-highlighted duration-200"
                >
                  Services
                </Link>

                {/* TODO : Restore */}
                {/* <Link
                  to="/projects"
                  className="block text-center text-4xl px-16 py-6 font-bold hover:text-primary-highlighted duration-200"
                >
                  Projects
                </Link>

                <Link
                  to="/articles"
                  className="block text-center text-4xl px-16 py-6 font-bold hover:text-primary-highlighted duration-200"
                >
                  Articles
                </Link> */}

                {/* <Link
                  to="/contact"
                  className="block text-center text-4xl px-16 py-6 font-bold hover:text-primary-highlighted duration-200"
                >
                  Contact
                </Link> */}
                <a
                  href="https://canopyinsulationco.dripjobs.com/appointmentrequests"
                  className="block text-center text-4xl px-16 py-6 font-bold hover:text-primary-highlighted duration-200"
                >
                  Contact
                </a>
              </nav>
            </div>
            <div className="grow bg-tertiary flex flex-col space-y-4 justify-center items-center">
              <div className="text-center">
                <Button
                  href="tel:814-229-0180"
                  icon={faPhoneAlt}
                  className="align-middle hover:bg-primary-highlighted active:bg-primary-darkened active:shadow-none text-2xl"
                >
                  814.229.0180
                </Button>
              </div>
              <div className="text-center space-x-8">
                <a href="https://www.facebook.com/canopysfi">
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    size="2x"
                    className="text-secondary hover:text-primary-highlighted hover:scale-125 duration-200"
                  />
                </a>

                <a href="https://www.instagram.com/canopysfi/">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-secondary hover:text-primary-highlighted hover:scale-125 duration-200"
                  />
                </a>

                <a href="mailto:canopysfi@gmail.com">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="2x"
                    className="text-secondary hover:text-primary-highlighted hover:scale-125 duration-200"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
