import * as React from "react"

const CarbonCreekIcon = ({ className }) => {
  return (
    <svg
      alt="Carbon Creek icon"
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 267 534"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <path d="M107.02,100.17l-32.32,39.012c-9.437,11.391 -26.205,12.877 -37.451,3.319c-0.003,-0.002 -0.005,-0.004 -0.007,-0.006c-5.289,-4.495 -8.598,-10.933 -9.2,-17.899c-0.601,-6.966 1.554,-13.889 5.991,-19.245c21.323,-25.738 57.965,-69.967 79.288,-95.705c0.289,-0.349 0.587,-0.691 0.894,-1.024c0.777,-0.859 1.62,-1.677 2.527,-2.448c0.002,-0.002 0.004,-0.004 0.007,-0.006c5.288,-4.494 12.123,-6.677 19.001,-6.068c6.877,0.61 13.234,3.962 17.672,9.318c21.322,25.738 57.964,69.967 79.287,95.705c4.438,5.356 6.593,12.279 5.991,19.245c-0.602,6.966 -3.911,13.404 -9.199,17.899c-0.003,0.002 -0.005,0.004 -0.007,0.006c-11.247,9.558 -28.015,8.072 -37.452,-3.319l-32.399,-39.108l0,40.091l86.58,104.507c9.437,11.391 7.97,28.374 -3.277,37.933c-0.002,0.002 -0.004,0.004 -0.007,0.005c-5.288,4.495 -12.123,6.678 -19.001,6.069c-6.877,-0.61 -13.234,-3.962 -17.672,-9.318l-46.623,-56.277l0,61.024l100.804,121.676c9.437,11.391 7.97,28.374 -3.277,37.933c-0.002,0.001 -0.004,0.003 -0.007,0.005c-5.288,4.495 -12.123,6.678 -19.001,6.068c-6.877,-0.609 -13.234,-3.961 -17.672,-9.317l-60.847,-73.446l0,148.768c0,4.712 -1.848,9.231 -5.137,12.563c-3.29,3.332 -7.752,5.203 -12.404,5.203c-5.644,0 -11.897,0 -17.541,0c-4.652,0 -9.114,-1.871 -12.403,-5.203c-3.29,-3.332 -5.138,-7.851 -5.138,-12.563l-0,-148.63l-60.558,73.097c-4.484,5.412 -10.907,8.799 -17.857,9.415c-6.949,0.615 -13.855,-1.59 -19.199,-6.132c-0.003,-0.002 -0.005,-0.004 -0.007,-0.005c-5.346,-4.544 -8.691,-11.052 -9.299,-18.093c-0.608,-7.041 1.57,-14.038 6.056,-19.452l100.864,-121.749l-0,-61.338l-46.588,56.235c-9.341,11.275 -25.937,12.745 -37.069,3.285c-0.002,-0.002 -0.005,-0.004 -0.007,-0.006c-5.344,-4.542 -8.688,-11.047 -9.296,-18.086c-0.608,-7.039 1.57,-14.034 6.054,-19.446l86.906,-104.901l-0,-39.591Z" />
    </svg>
  )
}

export default CarbonCreekIcon
